// ================================================================================================
//  File Name: components.scss
//  Description: Common components file to includ all theme specific custom components.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

@import 'bootstrap-extended/include'; // Bootstrap includes
@import 'components/include'; // Components includes

@import 'core/mixins/transitions'; // Template custom mixins

// Core
// ------------------------------
// layouts
@import 'core/layouts/content';
@import 'core/layouts/sidebar';
@import 'core/layouts/footer';
// menu
@import 'core/menu/navigation';

// Components
// ------------------------------
@import 'components/avatar';
@import 'components/search';
// @import 'components/chart';
@import 'components/bootstrap-social';
@import 'components/demo'; //! Plugins demo styles, remove in real project
@import 'components/customizer'; //! Customizer demo, remove in real project
@import 'components/divider';
@import 'components/timeline';
@import 'components/custom-options';

// Plugins
// ------------------------------
@import 'plugins/ui/breakpoints';
@import 'plugins/forms/select2/select2';
@import 'plugins/tables/datatables';
@import 'plugins/forms/form-number-input'; // ? globally required for cart dropdown

.li-profile-details-container {
	padding: 10px;

	box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
	background-color: #ffffff;
	border-radius: 0.5rem;

	border-left: 0 solid #00ff99;
	transition: border-left 300ms ease-in-out, padding-left 300ms ease-in-out;
}

.li-profile-details-container:hover {
	padding-left: 0.5rem;
	border-left: 0.5rem solid #00ff99;
}

.li-profile-details-container > :first-child {
	margin-top: 0;
}

.li-profile-details-container > :last-child {
	margin-bottom: 0;
}

.heading {
	color: #fff;
}
